.Header {
  padding-bottom: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    width: 300px;
    
    @media (max-width: 500px) {
      width: 150px;
    }
  }
}