
.Home {
  .Main {
    &__Searchable {
      h3 {
        font-size: 1.5em;
        font-weight: 500;
      }

      &__wrapper {
        width: 50%;
        min-width: 320px;
        display: flex;
        .slds-form-element {
          flex: 1;
        }
        button {
          color: white;
          min-width: 150px;
          margin-left: 1em;

          @media (max-width: 500px) {
            min-width: auto;
          }
        }
      }
    }

    &__List {
      .export-csv-btn {
        position: absolute;
        right: 3em;
        z-index: 10;
      }
      margin-top: 2em;
      .slds-spinner_container {
        position: fixed;
      }
      &__Body {
        padding: 2em;

        .slds-card__header {
          display: none;
        }

        .slds-section__title-action {
          background: none;
          .slds-truncate {
            flex: 1;
          }
        }
      }
    }

    .Table_Body {
      padding-left: 2em;
      &__Sub_Header {
        font-size: 1.25em;
      }
    }

    .Table_Header {
      display: flex;

      &__Mobile {
        position: relative;
        flex-direction: column;
        &__Row2 {
          margin: 10px;
          display: flex;
          img {
            width: 30px;
            margin-right: 5px;
          }

          .salesforce {
            margin-right: 2em;
          }

          .linkedin {
            
          }
        }

        &__Column_5 {
          display: flex;
          flex-wrap: wrap;
          span {
            font-weight: 400;
            align-self: center;
          }
          > .slds-badge {
            margin: 5px;
          } 
        }

        &__Column_6 {
          position: absolute;
          top: 0;
          right: 0;
          align-self: center;
          flex: 1 1 80px;
          .slds-button__icon {
            fill: #1b96ff;
          }
        }
      }

      .header {
        font-weight: bold;
      }

      img {
        width: 30px;
        margin-right: 5px;
      }

      &__Column_1 {
        flex: 1 1 35px;
      }

      &__Column_2 {
        flex: 2 1 34%;
        align-self: center;
        max-width: 32%;
        white-space: initial;
        padding-right: 1em;
      }

      &__Column_3 {
        flex: 2 1 13%;
        align-self: center;
      }

      &__Column_4 {
        flex: 2 1 13%;
        align-self: center;
      }

      &__Column_5 {
        flex: 4 1 40%;
        display: flex;
        flex-wrap: wrap;
        span {
          font-weight: 400;
          align-self: center;
        }
        > .slds-badge {
          margin: 5px;
        } 
      }

      &__Column_6 {
        align-self: center;
        flex: 1 1 80px;
        .slds-button__icon {
          fill: #1b96ff;
        }
      }
    }

    .border-bottom {
      border-bottom: 1px solid #ddd;
      padding-bottom: 5px;
    }

    .Table_Body {
      &__Item {
        margin-top: 1em;
        &__Topic {
          font-size: 1.5em;
          font-weight: bold;
        }
      }
    }
  }
}

.no-scroll {
  position: fixed;
  overflow: hidden;
  z-index: 10;
}

.SF_Question_Modal {
  .slds-m-bottom_large {
    display: flex;
    
    .slds-form-element__control {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      border: 1px solid #eee;
      border-radius: 2px;
      background: #ddd;
  
      > span {
        color: #888;
        padding: 0 1em;
      }
    }
  }
}

.highlight {
  background-color: darkorange;
}